import React from "react";
import { useNavigate } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";
import './Login.css';
import axios from 'axios';
import { useState } from 'react';

function Login( { neighborhoodKeys }) {
    const navigate = useNavigate();

    const [formData, setFormData] = useState();

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidEmail(formData.email)) {
            alert('Invalid email address format');
            return;
        }

        try {
            const updatedFormData = {
                ...formData,
                neighborhoodKeys: neighborhoodKeys
            };

            const response = await axios.post('/api/auth/login', updatedFormData); 
            alert('Go to your email to login!');
        } catch (error) {
            console.error('There was an error logging in', error);
            alert('There was an error logging in. Check your internet connection.');
        }
    }; 

    return (
        <div>
            <div className="BackArrow">
                <IoArrowBackOutline  onClick={() => navigate('/')} size={30} className="Arrow" style={{marginLeft: 'calc(100vw * 0.18)'}}/>
            </div>   
            <div className="Login">
                <h1>Login</h1>
                <form>
                    <div className="Form">
                        <div className="Email">
                            <label class="form-label" for="email">Email</label>
                            <input id="email" className="FormInput" onChange={handleChange} name="email" required autocomplete="email" type="email"/>
                        </div>
                        {/* <div class="col-md-6">
                            <label class="form-label" for="password">Password</label>
                            <div class="input-group">
                                <input id="password" class="form-control" name="password" required minlength="8" maxlength="25" type="password"/>
                                <button class="btn btn-secondary" type="button">
                                    <svg class="svg-inline--fa fa-eye" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16">
                                        <path fill="currentColor" d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div> */}
                        <button type="submit" className="SignUpButton" onClick={handleSubmit}>Log in</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;