import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';

function EmailVerification({ setIsLoggedIn, setNeighborhoods }) {
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [message, setMessage] = useState('Verifying your email...');

    const fetchNeighborhoodsById = async () => {
        try {
            const params = new URLSearchParams(window.location.search); // Get the current URL query string
            const neighborhoodKeys = params.get('neighborhoodKeys'); // Get the neighborhoodKeys from the query string

            const getNeighborhoods = await axios.get('/api/neighborhoodsByIds', { 
                params: {
                    neighborhoodKeys: neighborhoodKeys // Send keys as a comma-separated string
                }
            });

            const neighborhoodsWithImages = await Promise.all(
                getNeighborhoods.data.map(async (neighborhood) => {
                  try {
                    const image = await axios.get(`/api/neighborhoods/${neighborhood.keyneighborhood}/image`); 
                    
                    return {
                      image: image.data,
                      ...neighborhood,
                    };
                  } catch (error) {
                    console.error('Error fetching image:', error);
                    return {
                      image: null,
                      ...neighborhood,
                    };
                  }
                })
            );
            setNeighborhoods(neighborhoodsWithImages);            
        } catch (err) {
            console.error('Error fetching neighborhoods:', err);
        }
    }

    useEffect(() => {
        const verifyAndLogin = async () => {
            try {
                const loginResponse = await axios.post('/api/auth/verifylogin', { token }); 
                setMessage('Success!');
                const { accessToken } = loginResponse.data;
                localStorage.setItem('token', accessToken); 
                setIsLoggedIn(true); 
                alert('You are logged in now. Happy exploring!');
                await fetchNeighborhoodsById();
                navigate('/');
            } catch (error) {
                setMessage(error.response?.data.message + '. Log in again.' || 'Something went wrong');
            };
        };
        verifyAndLogin();
    }, []);

    return (
        <div className="verification-container">
            <h2>Email Verification</h2>
            <p>{message}</p>
        </div>
    );
}

export default EmailVerification;
