import React, { useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Neighborhoods.css';
import axios from 'axios';
import { IoRefresh } from "react-icons/io5";
import NeighborhoodItem from "./NeighborhoodItem"

function Neighborhoods({ neighborhoods, fetchNeighborhoods, onNeighborhoodClick, isLoggedIn, setIsLoggedIn }) {

  const navigate = useNavigate();

  const [savedNeighborhoods, setSavedNeighborhoods] = useState([]);

  const fetchSavedNeighborhoods = async () => {
    if(isLoggedIn){
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/neighborhoodssaved', { 
          headers: {
            Authorization: `Bearer ${token}` // Add token in the Authorization header
          }
        });

        setSavedNeighborhoods(response.data.map(neighborhood => neighborhood.keyneighborhood));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsLoggedIn(false);
          alert('Session expired. Please log in again.');
          localStorage.removeItem('token'); 
          navigate('/login');
        } else {
          alert('Error fetching saved neighborhoods');
        }
      }
    }
  };

  useEffect(() => {
    fetchSavedNeighborhoods();
  }, []);


  const handleSave = async (neighborhoodId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/api/neighborhoods/${neighborhoodId}/save`, { token: token }); //implement person-neighborhood save, +1 like neighborhood
      fetchSavedNeighborhoods();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        alert('Session expired. Please log in again.');
        navigate('/login');
      } else {
        alert('Error saving neighborhood');
      }
    } 
  }

  return (
    <div>
      <div className="BackArrow">
        <IoRefresh onClick={fetchNeighborhoods} size={30} className="Arrow" style={{ marginLeft: 'calc(100vw * 0.1)' }} />
      </div>
      <div className="Neighborhoods" style={{ marginLeft: 'calc(100vw * 0.1)', marginRight: 'calc(100vw * 0.1)' }}>
        {neighborhoods?.map((neighborhood) => (
          <NeighborhoodItem key={neighborhood.keyneighborhood} neighborhood={neighborhood} onNeighborhoodClick={onNeighborhoodClick} savedNeighborhoods={savedNeighborhoods} handleSave={handleSave} isLoggedIn={isLoggedIn} profile={false}/>
        ))}
      </div>
    </div>
  );
}

export default Neighborhoods;
