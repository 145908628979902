import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import './Neighborhoods.css';


function NeighborhoodItem ({ neighborhood, onNeighborhoodClick, savedNeighborhoods, handleSave, isLoggedIn, profile }){
    const navigate = useNavigate();

    return (
        <div 
            className="Item" 
            key={neighborhood.keyneighborhood} 
        >
            {neighborhood.image ? (
                <img 
                src={neighborhood.image} 
                className="Picture" 
                alt={neighborhood.name} 
                onClick={() => onNeighborhoodClick(neighborhood.keyneighborhood)}
                />
            ) : (
                <div 
                className="Picture"
                style={{ 
                    borderRadius: '20px', 
                    border: '2px solid lightgrey' 
                }}
                onClick={() => onNeighborhoodClick(neighborhood.keyneighborhood)}
                />
            )}
            <div className="Info" style={{ textAlign: 'left' }}>
                <h2>{neighborhood.name}</h2>
                <p className="LocationNeighborhoods">{neighborhood.locationaddress}</p>
                <p className="DescriptionNeighborhoods">{neighborhood.shortdescription}</p>
                <div className="TypesNeighborhoods">
                {neighborhood.tags.map((type, index) => (
                    <p key={index} className="TypeNeighborhoods">{type}</p>
                ))}
                </div>
            </div>
            {!profile && (
                savedNeighborhoods.includes(neighborhood.keyneighborhood) ? (
                    <div className="HeartContainer">
                        <IoMdHeart size={40} className="Heart" style={{ color: 'red' }}/>
                    </div>
                ) : (
                    <div className="HeartContainer">
                        <IoMdHeartEmpty
                            onClick={() => !isLoggedIn ? navigate('/signup') : handleSave(neighborhood.keyneighborhood)}
                            size={40}
                            className="Heart"
                        />
                    </div>
                )
            )}
        </div>
    )
}

export default NeighborhoodItem;